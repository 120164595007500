<template>
  <button
    v-if="inactive == false"
    @click="$emit('input')"
    class="btn shadow-0"
    :data-bs-toggle="dataToggle"
    :data-bs-target="exampleModal"
    :class="
      classBtn + ' ' + tooltipDelay + ' ' + tooltipPlacement ||
      (device == 'mobile' && classBtn == 'tooltiper' ? 'tooltiper-mobile' : '')
    "
    :data-tooltip="tooltipMsg"
  >
    <!-- <Icon
      :icon="leftBtn"
      :width="width"
      :height="height"
      :class="classLeftBtn"
    /> -->
    <component
      :is="icon"
      :width="widthIcon"
      :height="heightIcon"
      fill="none"
      :class="classBtnIcon || (name == '' || name == null ? '' : 'me-2')"
    ></component>
    <span class="fnt-regular" :class="className">{{
      name != null ? $t(name) : name
    }}</span>
    <Icon
      :icon="rightBtn"
      :width="width"
      :height="height"
      class="ms-2 d-none"
    />
  </button>
</template>

<script>
import {
  // ListOutline,
  QuestionOutline,
  BIconFilterOutline,
  CloseOutline,
  DisplayConfig,
  DeleteFill,
  EditFill,
  AddFill,
  SortArrow,
  Drag,
  RemoveFill,
  moveDown,
  ExpandIn,
  ExpandOut,
  AddOutline,
  OpenEye,
  NotiOutline,
  moveUp,
  EdituserField,
  EdituserList,
  SearchOutline,
  CorrectFill,
  ImportOutline,
  ExportOutline,
} from "@/components/icon/index";
import { Icon } from "@iconify/vue";
export default {
  props: {
    name: String,
    leftBtn: String,
    rightBtn: String,
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    widthIcon: {
      type: Number,
      default: 24,
    },
    heightIcon: {
      type: Number,
      default: 24,
    },
    dataToggle: { type: String, default: "" },
    exampleModal: { type: String, default: "" },
    classBtn: { type: String, default: "" },
    classLeftBtn: String,
    tooltipMsg: { type: String, default: "" },
    icon: { type: String },
    className: { type: String },
    classBtnIcon: { type: String },
    // placement: { type: String, default: "top" },
    deleteinform: { type: Boolean, default: false },
    tooltipDelay: { type: String, default: "150ms" },
    tooltipPlacement: { type: String, default: "tooltip-top" },
  },
  emits: ["input"],
  components: {
    // Icon name
    QuestionOutline,
    Icon,
    BIconFilterOutline,
    CloseOutline,
    DisplayConfig,
    DeleteFill,
    EditFill,
    AddFill,
    SortArrow,
    Drag,
    RemoveFill,
    moveDown,
    ExpandIn,
    ExpandOut,
    AddOutline,
    OpenEye,
    NotiOutline,
    moveUp,
    EdituserField,
    EdituserList,
    SearchOutline,
    CorrectFill,
    ImportOutline,
    ExportOutline,
  },
  data() {
    return {
      iconType: "",
      inactive: false,
      hover: false,
    };
  },
  mounted() {
    // this.customTolltip();
  },
  methods: {
    //inti tooltip
    // customTolltip() {
    //   const btnTooltip = document.querySelectorAll(
    //     `button[data-bs-toggle="${this.dataToggle}"]`
    //   );
    //   Array.from(btnTooltip).forEach(
    //     (tooltipNode) =>
    //       new Tooltip(tooltipNode, {
    //         trigger: "hover",
    //         delay: { show: 500, hide: 200 },
    //         html: true,
    //       })
    //   );
    // },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: max-content !important;
  position: relative;
}
svg {
  transition: 0.2s;
}
.overlay-delete {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(216, 42, 76, 0.15);
  width: 100%;
  height: 100%;
  opacity: 0;
}
.deleteinform {
  position: relative;
  button {
    transition: 150ms;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    z-index: 99;
    svg {
      width: 30px;
      height: 30px;
    }
    &:hover {
      transform: scale(1.2);
      background-color: $danger;
      svg {
        fill: $white !important;
      }
    }
  }
}
// button{
//   height: 36px !important;
// }
.tooltip-top {
  &::before {
    left: 50%;
    bottom: 130%;
    transform: translateX(-50%);
  }
  &::after {
    left: 47%;
    bottom: calc(100% + 8px);
    transform: translateX(-50%);
  }
}
.tooltip-top-mobile {
  &::before {
    left: calc(100% - 63px);
    bottom: 115%;
    transform: translateX(-50%);
  }
  &::after {
    left: 47%;
    bottom: calc(100% + 2px);
    transform: translateX(-50%);
  }
}
.tooltip-bottom {
  &::before {
    left: 50%;
    top: 110%;
    transform: translateX(50%);
  }
  &::after {
    left: 47%;
    bottom: calc(0% - 8px);
    transform: translateX(-50%);
  }
}
.tooltip-bottom-mobile {
  &::before {
    left: -145%;
    top: 102%;
    transform: translateX(-50%);
  }
  &::after {
    left: 40%;
    bottom: calc(100% + -43px);
    transform: translateX(-50%);
  }
}
.tooltip-left {
  &::before {
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    right: calc(100% + 8px);
    top: 45%;
    transform: rotate(45deg) translateY(-50%);
  }
}
.tooltip-left-edit {
  &::before {
    right: 49px;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    right: calc(100% + 8px);
    top: 45%;
    transform: rotate(45deg) translateY(-50%);
  }
}
.tooltip-left-mobile {
  &::before {
    right: 43px;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    right: calc(100% + -9px);
    top: 45%;
    transform: rotate(45deg) translateY(-50%);
  }
}
.tooltip-right {
  &::before {
    left: 107%;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    left: calc(100% + 8px);
    top: 45%;
    transform: rotate(45deg) translateY(-50%);
  }
}
.tooltiper {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
.tooltiper-mobile {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 2.6vw !important;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
</style>
