<template>
  <div
    class="input-group search rounded-2 d-flex align-items-center"
    :class="classAutoSearch + ' ' + size"
    :style="
      strSearch != '' && strSearch != null
        ? 'padding: 1px 32px 1px 14px;'
        : 'padding: 1px 16px;'
    "
    id="input"
    v-if="mode == 'auto'"
  >
    <Icon
      icon="bytesize:search"
      class="icon-search text-black-500"
      width="20"
      height="20"
      v-if="device == 'mobile'"
    />
    <Icon
      v-else
      icon="bytesize:search"
      class="icon-search text-black-500"
      :width="size == 'small' ? 16 : size == 'medium-small' ? 18 : 24"
      :height="size == 'small' ? 16 : size == 'medium-small' ? 18 : 24"
    />
    <input
      type="text"
      :placeholder="hint"
      id="input"
      v-model="strSearch"
      @change="search()"
      @keypress="search()"
      @keyup.delete="search()"
      :class="size || device == 'mobile' ? 'mobile' : ''"
    />

    <button
      v-show="strSearch != '' && strSearch != null"
      class="btn p-0 m-0 close-search"
      @click="clean()"
    >
      <Icon icon="ep:close" class="text-black-500" width="24" height="24" />
    </button>
  </div>
  <div
    class="input-group search rounded-2 d-flex align-items-center"
    :class="alertMsgStatus == true ? 'invalid' : '' + classEnterSearch"
    :style="
      strSearch != '' && strSearch != null
        ? 'padding: 1px 32px 1px 14px;'
        : 'padding: 1px 16px;'
    "
    id="input-enter"
    v-if="mode == 'enter'"
  >
    <Transition name="fade">
      <div class="tooltip-search rounded-2" v-show="blnShowTooltip == true">
        <span>{{ $t(strTooltipMsg, { numberCharacters: "1" }) }}</span>
      </div>
    </Transition>
    <Icon
      icon="bytesize:search"
      class="icon-search text-black-500"
      width="24"
      height="24"
      :color="alertMsgStatus == true ? '#d82a4c' : ''"
    />
    <input
      type="text"
      :placeholder="hint"
      id="input-enter"
      v-model="strSearch"
      :maxlength="maxlength"
      :class="size || device == 'mobile' ? 'mobile' : ''"
      @keypress="checkKey($event)"
      @change="Valid()"
    />
    <button
      v-show="strSearch != '' && strSearch != null"
      class="btn p-0 m-0 close-search"
      @click="clean()"
    >
      <Icon icon="ep:close" class="text-black-500" width="24" height="24" />
    </button>
  </div>
  <div
    :class="alertMsgStatus == true ? '' : 'd-none'"
    class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
  >
    <Icon
      :class="alertMsgStatus == true ? '' : 'd-none'"
      icon="carbon:warning"
      class="me-1"
    />
    {{ $t(alertMsgInput) }}
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Validation from "@/typescript/validator/validator";
export default {
  props: {
    hint: {
      type: String,
      default: "input",
    },
    size: { type: String, default: "small" },
    mode: { type: String, default: "auto" },
    classAutoSearch: { type: String },
    classEnterSearch: { type: String },
    maxlength: { type: String },
    validate: { type: String, default: "" },
    TooltipMsg: { type: String },
  },
  data() {
    return {
      strSearch: null,
      blnShowTooltip: false,
      strTooltipMsg: "1201",
      alertMsgStatus: false,
      alertMsgInput: "",
    };
  },
  components: {
    Icon,
  },
  emits: ["onSearch", "onClean"],
  mounted() {
    if (this.TooltipMsg != undefined && this.TooltipMsg != null) {
      this.strTooltipMsg = this.TooltipMsg;
    }
  },
  methods: {
    //เรียกฟังก์ชัน ค้นหาพร้อมกับส่งข้อมูลที่ต้องการค้นหา
    search() {
      setTimeout(() => {
        this.$emit("onSearch", this.strSearch?.trim());
      }, 10);
    },
    //ยกเลิกคำสั่งค้นหา
    clean() {
      this.strSearch = null;
      // this.$emit("onClean");

      this.$emit("onSearch", "");
    },
    //ยกเลิกคำสั่งค้นหา
    cleanfromlist() {
      this.strSearch = null;
    },

    // Enter search
    checkKey(e) {
      if (e.key == "Enter") {
        if (this.strSearch?.length > 1) {
          if (this.maxlength == undefined) {
            this.blnShowTooltip = false;
            this.search();
          } else {
            if (
              this.strSearch.length < this.maxlength ||
              this.strSearch == ""
            ) {
              this.blnShowTooltip = true;
              setTimeout(() => {
                this.blnShowTooltip = false;
              }, 3000);
            } else {
              this.blnShowTooltip = false;
              this.search();
            }
          }
        } else if (
          this.strSearch?.length < 2 ||
          this.strSearch == null ||
          this.strSearch == ""
        ) {
          this.blnShowTooltip = true;
          setTimeout(() => {
            this.blnShowTooltip = false;
          }, 3000);
        }
      }

      if (this.validate != null && e.key != "Enter") {
        let char = String.fromCharCode(e.keyCode);
        if (this.validate == "number") {
          if (/^[0-9]+$/.test(char)) {
            this.alertMsgStatus = false;
            return false;
          } else {
            this.alertMsgInput = "56";
            this.alertMsgStatus = true;
            e.preventDefault();
            return true;
          }
        }
      }
    },
    Valid() {
      this.strSearch?.trim();
      if (this.strSearch === "" || this.strSearch === undefined) {
        return;
      } else {
        if (this.validate == "email") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          // console.log(this.alertMsgStatus);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "57";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "number") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);

          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "56";
            // console.log(this.alertMsgStatus);
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "numeric") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);

          if (this.alertMsgStatus == true) {
            // console.log(this.alertMsgStatus);
            this.alertMsgInput = "899";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "phonenumber") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);

          if (this.alertMsgStatus == true) {
            // console.log(this.alertMsgStatus);
            this.alertMsgInput = "899";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "webUrl") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "59";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "facebookpage") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "58";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "letterTH") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "letterEN") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "InitialTH") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "InitialEN") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "TextDisplayTH") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "TextDisplayEN") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "addressTH") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "45";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else if (this.validate == "currency") {
          this.alertMsgStatus = false;
          this.$refs["input"].value = Intl.NumberFormat("en-US").format(
            this.strSearch.replaceAll(",", "")
          );
          return Validation[this.validate](this.strSearch);
        } else if (this.validate == "letterENUnderScore") {
          this.alertMsgStatus = !Validation[this.validate](this.strSearch);
          if (this.alertMsgStatus == true) {
            this.alertMsgInput = "46";
            return true;
          } else if (this.alertMsgStatus == false) {
            return Validation[this.validate](this.strSearch);
          }
        } else {
          if (this.validate !== "") {
            // console.log(this.validate);
            this.alertMsgStatus = !Validation[this.validate](this.strSearch);
            // console.log(Validation[this.validate](this.strSearch));
            if (this.alertMsgStatus == true) {
              this.alertMsgInput = this.$t("202", {
                fieldname: this.$t(this.label).toLowerCase(),
              });
              return true;
            } else if (this.alertMsgStatus == false) {
              return Validation[this.validate](this.strSearch);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group-disable {
  border: 1px solid $black-400 !important;
  background: $black-200 !important;
  pointer-events: none;
}
.input-group-large {
  width: 105%;
}
.invalid {
  border: 1px solid $danger !important;
  &:hover {
    .icon-search {
      color: $danger !important;
    }
  }
}
.input-group {
  border: 1px solid $black-500;
  // padding: 1px 16px ;
  // overflow: hidden;
  background: $white;
  border-radius: 4px;
  height: 36px;
  .close-search {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .icon-search {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 16px;
    background: none !important;
  }
  input[type="text"] {
    border-radius: 4px;
    border-color: transparent;
    padding-left: 40px;
    background: none;
    border: none !important;
    outline: none !important;

    &::placeholder {
      font-weight: 400 !important;
      color: $black-500;
      padding: 4px 0;
      font-size: 16px !important;
    }
  }
  &:hover {
    border: 1px solid $primary;
    .icon-search {
      color: $primary;
    }
  }
  &:focus {
    border: 1px solid $primary !important;
  }
}
.small {
  height: 30px !important;
  padding: 1px 1px !important;
  .icon-search {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 16px;
    background: none !important;
  }
  input[type="text"] {
    border-radius: 4px;
    border-color: transparent;
    padding-left: 30px;
    background: none;
    border: none !important;
    outline: none !important;

    &::placeholder {
      font-weight: 400 !important;
      color: $black-500;
      padding: 4px 0;
      font-size: 14px !important;
    }
  }
}
.medium-small {
  height: 38px !important;
  .icon-search {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    font-size: 16px;
    background: none !important;
  }
  input[type="text"] {
    border-radius: 4px;
    border-color: transparent;
    padding-left: 30px;
    background: none;
    border: none !important;
    outline: none !important;

    &::placeholder {
      font-weight: 400 !important;
      color: $black-500;
      padding: 4px 0;
      font-size: 14px !important;
    }
  }
}
.medium {
  height: 48px !important;
}
.mobile {
  width: 100% !important;
  &::placeholder {
    font-size: 14px !important;
  }
}
.tooltip-search {
  position: absolute;
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
  background-color: $black;
  padding: 8px;
  width: max-content;
  height: fit-content;
  z-index: 9999;
  span {
    color: $white;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    line-height: 16px;
  }
  &::before {
    position: absolute;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    top: calc(100% - 9px);
    transform: rotate(-45deg) translateX(-50%);
    left: 50%;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
