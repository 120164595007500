<template>
  <div :class="size + ' ' + 'avatar' + ' ' + usertype" v-if="blnvalue == false">
    <img :src="image" alt="" @error="handleImageError()" />
  </div>
  <div :class="size + ' ' + 'avatar' + ' ' + usertype" v-if="blnvalue == true">
    <FindUserFill class="fill-black-400" />
  </div>
</template>

<script>
import { FindUserFill } from "@/components/icon/index";
export default {
  props: {
    image: { type: String },
    size: { type: String, default: "medium" },
    usertype: { type: String, default: "" },
    blnvalue: { type: Boolean, default: false },
  },
  components: {
    FindUserFill,
  },
  methods: {
    handleImageError() {
      this.$emit("errorImg");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  border-radius: 50%;
  border: solid 4px $white;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: solid 3px $white;
    border-radius: 50%;
  }
  .officer {
    border-color: $info;
  }
  .student {
    border-color: $secondary;
  }
}
.officer {
  border-color: $info;
}
.student {
  border-color: $secondary;
}
.medium {
  width: 100px !important;
  height: 100px !important;
}
.medium-mobile {
  width: 85px !important;
  height: 85px !important;
}
.small {
  width: 60px !important;
  height: 60px !important;
}
.large {
  width: 200px !important;
  height: 200px !important;
}
</style>
