export default {
  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus"])},
  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User field"])},
  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
  "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type"])},
  "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
  "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
  "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit"])},
  "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search campus"])},
  "15": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("first")), "-", _interpolate(_named("last")), " of ", _interpolate(_named("total")), " items"])},
  "16": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "/Page"])},
  "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to "])},
  "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
  "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add campus"])},
  "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main campus"])},
  "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display configuration"])},
  "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is linked to the other data, cannot be deleted."])},
  "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is the main campus, cannot be deleted. Please switch this campus to branch campus."])},
  "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added"])},
  "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully edited"])},
  "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully deleted"])},
  "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus not found"])},
  "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter campus information"])},
  "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus infomation"])},
  "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Require*"])},
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in campus name"])},
  "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in campus abbreviation"])},
  "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in campus address"])},
  "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in phone number"])},
  "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in email address"])},
  "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in Facebook account"])},
  "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in fax number"])},
  "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in website"])},
  "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter data in Thai"])},
  "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter data in English"])},
  "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter (A-Z),(a-z),(0-9),(ก-ฮ)"])},
  "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This campus name has already been used. Please try another name."])},
  "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This campus abbreviation has already been used. Please try another abbreviation."])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This campus address has already been used. Please try another address."])},
  "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This phone number has already been used. Please try another phone number."])},
  "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address has already been used. Please try another email address."])},
  "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Facebook account has already been used. Please try another Facebook account."])},
  "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This fax number has already been used. Please try another fax number."])},
  "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website has already been used. Please try another website."])},
  "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter numbers only (0-9)"])},
  "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address in the format name", "@", "email.com, no space"])},
  "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Facebook account in the format https://www.facebook.com/me, no space"])},
  "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your website in the format https://www.website.ac.th/, no space"])},
  "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this campus?"])},
  "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus name (EN)"])},
  "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus abbreviation (EN)"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus address (EN)"])},
  "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus name (TH)"])},
  "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus abbreviation (TH)"])},
  "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus address (TH)"])},
  "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
  "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch campus"])},
  "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All fields"])},
  "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible fields"])},
  "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
  "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
  "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search position"])},
  "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add position"])},
  "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position name (EN)"])},
  "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position name (TH)"])},
  "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work period"])},
  "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position not found"])},
  "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this position?"])},
  "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
  "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months"])},
  "95": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldname")), " not found"])},
  "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user type"])},
  "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user type"])},
  "98": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this user type?"])},
  "99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type name (EN)"])},
  "100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type name (TH)"])},
  "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No grouped data available for filter."])},
  "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search degree level"])},
  "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add degree level"])},
  "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this degree level?"])},
  "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree level name (EN)"])},
  "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree level name (TH)"])},
  "107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix name (EN)"])},
  "108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix name (TH)"])},
  "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix abbreviation (EN)"])},
  "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix abbreviation (TH)"])},
  "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add prefix"])},
  "112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search prefix"])},
  "113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this prefix?"])},
  "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search parameter"])},
  "115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add parameter"])},
  "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter name"])},
  "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value type"])},
  "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean"])},
  "123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user field"])},
  "125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user field"])},
  "126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name (EN)"])},
  "127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field type"])},
  "128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation"])},
  "129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data type"])},
  "130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiable"])},
  "133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeatable"])},
  "134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not null"])},
  "135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By end user"])},
  "136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
  "137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
  "138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
  "139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not required"])},
  "142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not identifiable"])},
  "143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not repeatable"])},
  "144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Null"])},
  "145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this user field?"])},
  "146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/Time"])},
  "147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiable"])},
  "148": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeatable"])},
  "149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is control field, cannot be deleted."])},
  "150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name (EN)"])},
  "151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add template"])},
  "152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search template"])},
  "153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
  "154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search division"])},
  "156": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add division"])},
  "157": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division name (EN)"])},
  "158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division abbreviation (EN)"])},
  "159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division address (EN)"])},
  "160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division name (TH)"])},
  "161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division abbreviation (TH)"])},
  "162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division address (TH)"])},
  "163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is the default template, cannot be deleted. Please switch another template to be default template"])},
  "164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search academic unit"])},
  "165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add academic unit"])},
  "166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit name (EN)"])},
  "167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit abbreviation (EN)"])},
  "168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit name (TH)"])},
  "169": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit abbreviation (TH)"])},
  "170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In process"])},
  "171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend"])},
  "172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item has been used recently, cannot be deleted."])},
  "173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal server error"])},
  "174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error(API). Please try again later. "])},
  "175": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
  "176": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The requested ", _interpolate(_named("URL")), " was not found on this server."])},
  "177": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to homepage"])},
  "178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your username and password correctly."])},
  "179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading... Please wait"])},
  "180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
  "181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname, Lastname"])},
  "184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname (TH)"])},
  "185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname (TH)"])},
  "186": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to delete this ", _interpolate(_named("fieldname")), "?"])},
  "187": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic data setting"])},
  "188": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value list"])},
  "191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searchable"])},
  "192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name (TH)"])},
  "193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter user field information"])},
  "194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in field name"])},
  "195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field name has already been used. Please try another name."])},
  "196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, Do you want to add this user field?"])},
  "197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
  "198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not ready"])},
  "199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter (A-Z) (a-z) (0-9) (ก-ฮ) (/ . () - )"])},
  "200": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter ", _interpolate(_named("fieldname")), " information"])},
  "201": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldname")), " information"])},
  "202": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in ", _interpolate(_named("fieldname"))])},
  "203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This division has already been used. Do you want to add this?"])},
  "205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This academics unit has already been used. Do you want to add this?"])},
  "206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
  "208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 1"])},
  "209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 2"])},
  "210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 3"])},
  "211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop field here"])},
  "212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group not found"])},
  "213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field not found"])},
  "214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to quit?"])},
  "217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This campus cannot be edited. The main campus has already been used. Please switch main campus to branch campus."])},
  "218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, quit"])},
  "219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "220": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This image size is too large. The maximum image size is ", _interpolate(_named("filesize")), " MB."])},
  "221": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This ", _interpolate(_named("fieldname")), " has already been used. Please try another name."])},
  "222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item was deleted recently, cannot be edited."])},
  "223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, something(Page) went wrong. The system is refreshing to the homepage."])},
  "224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required, cannot be deleted."])},
  "225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group of field"])},
  "226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control field"])},
  "227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item has been used recently, cannot be edited."])},
  "228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is control field, cannot be edited."])},
  "229": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This dimension size is too large. The maximum dimension size is ", _interpolate(_named("width")), " x ", _interpolate(_named("height")), "."])},
  "230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose images type only (.jpeg, .png)"])},
  "231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter (A-Z) (a-z) (space) (/ . () _ -)"])},
  "232": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter (ก-ฮ) (space) (/ . () _ -)"])},
  "233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus name"])},
  "234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter (A-Z) (a-z) (0-9) (/ . () - ,)"])},
  "235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter (ก-ฮ) (0-9) (/ . () - ,)"])},
  "236": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not searchable"])},
  "237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course opening system"])},
  "240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class size"])},
  "241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search class size"])},
  "242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add class size"])},
  "243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class size (students)"])},
  "244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter class size information"])},
  "245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class size information"])},
  "247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search classroom types"])},
  "248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add classroom types"])},
  "249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom types"])},
  "250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom types (TH)"])},
  "251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of seats"])},
  "252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter classroom type information"])},
  "253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom type information"])},
  "254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching"])},
  "255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching types"])},
  "256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching types (TH)"])},
  "257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add teaching type"])},
  "258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter teaching type information"])},
  "259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching type information"])},
  "260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search teaching types/modes"])},
  "261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching modes"])},
  "262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching modes (TH)"])},
  "263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add teaching mode"])},
  "264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter teaching mode information"])},
  "265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching mode information"])},
  "266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination"])},
  "267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search exam types/duration"])},
  "268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam types"])},
  "269": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam types (TH)"])},
  "270": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exam type"])},
  "271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter exam type information"])},
  "272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam type information"])},
  "273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search exam types/duration"])},
  "274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam duration"])},
  "275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam duration (TH)"])},
  "276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exam duration"])},
  "278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter exam duration information"])},
  "279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam duration information"])},
  "280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User information"])},
  "281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this user type?"])},
  "282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this template?"])},
  "283": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "284": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please select ", _interpolate(_named("fieldname"))])},
  "285": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter user information"])},
  "286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User information"])},
  "287": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add uesr information"])},
  "288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum management"])},
  "289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program details"])},
  "290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Program"])},
  "291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter program details"])},
  "292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum ID"])},
  "295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program title (TH)"])},
  "296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program title"])},
  "297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree offered and field of study (TH)"])},
  "298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree offered and field of study"])},
  "299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviation (TH)"])},
  "300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviation"])},
  "301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree level"])},
  "302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of program"])},
  "303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic term"])},
  "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program abbreviation"])},
  "306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
  "307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve data"])},
  "308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't filled in the curriculum ID. This program will be temporarily active."])},
  "309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After receiving the curriculum ID, please fill in the required information."])},
  "310": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ProgramtitleTH")), " ", _interpolate(_named("ProgramtitleEN")), " ", _interpolate(_named("version")), " ", _interpolate(_named("Year")), " has already been used. Please edit program details."])},
  "311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program abbreviation must be three capital letters."])},
  "312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results: "])},
  "313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by:"])},
  "314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please search user information"])},
  "317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by user ID, name "])},
  "318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
  "319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New curriculum"])},
  "320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified curriculum"])},
  "321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a template"])},
  "322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a template"])},
  "323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blank template"])},
  "324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Draft)"])},
  "325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
  "326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More templates"])},
  "327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft curriculum"])},
  "329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit curriculum"])},
  "330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft curriculum not found"])},
  "331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search draft curriculum"])},
  "332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add draft curriculum"])},
  "333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found .... draft curriculums"])},
  "334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program structure"])},
  "337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects"])},
  "338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education plan"])},
  "339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject codes"])},
  "340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalent Subjects"])},
  "341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program name"])},
  "343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This campus is currently in use"])},
  "344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIN"])},
  "345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIN to confirm deleting"])},
  "346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload icon"])},
  "347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons"])},
  "348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My icons"])},
  "349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search icons"])},
  "350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file type is not supported, please upload SVG file"])},
  "351": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This icon file is too large. The maximum size is ", _interpolate(_named("filesize")), " MB."])},
  "352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon will be displayed when this user field is on the heading 3 only"])},
  "354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this campus's status?"])},
  "355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIN to confirm editing"])},
  "356": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have entered wrong PIN more than ", _interpolate(_named("number")), " times. Please contact the officer tel. ", _interpolate(_named("phoneno"))])},
  "357": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have entered wrong PIN, remaining: ", _interpolate(_named("number"))])},
  "358": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to change ", _interpolate(_named("branch")), " campus to the main campus?"])},
  "359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus address (EN)"])},
  "360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
  "361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub district "])},
  "363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
  "364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
  "365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone (TH)"])},
  "366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone (EN)"])},
  "367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax (TH)"])},
  "368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax (EN)"])},
  "369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House No., Building name, Street (TH)"])},
  "370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House No., Building name, Street (EN)"])},
  "371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus address (TH)"])},
  "372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example of phone number"])},
  "373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example of fax number"])},
  "374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum image size is 2 MB."])},
  "375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example \nEx 1: 075643210 or 0-7564-3210 \nEx 2: 075643210 ext. 1 - 4 \nEx 3: 075643210, 075484549"])},
  "376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub district District Province Postal code (TH)"])},
  "377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub district District Province Postal code (EN)"])},
  "378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit campus"])},
  "379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the main campus, cannot be changed."])},
  "380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a template"])},
  "381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum"])},
  "382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General education"])},
  "383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree level"])},
  "384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the program"])},
  "385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter 14 digit curriculum ID "])},
  "386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General education details"])},
  "387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course category name"])},
  "388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit position"])},
  "389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division address (TH)"])},
  "390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division address (EN)"])},
  "391": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have entered wrong pin more than ", _interpolate(_named("LOGINFAILNUM")), " time. Please contact the officer tel ", _interpolate(_named("LockContact"))])},
  "392": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have entered wrong pin, remaining: ", _interpolate(_named("LONGINFAILNUM"))])},
  "393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit division"])},
  "394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user type"])},
  "395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-division status will also be changed."])},
  "396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-division status will also be deleted."])},
  "397": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to change this ", _interpolate(_named("fieldname")), "'s status?"])},
  "398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit degree level"])},
  "399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit prefix"])},
  "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit academics unit"])},
  "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])},
  "402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The division has already been used and cannot be changed. Please switch all sub-division' status to Close ."])},
  "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main-division status will also be changed."])},
  "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in campus name before switching to main campus."])},
  "405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".jpeg, .png"])},
  "406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user information"])},
  "407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this division's status?"])},
  "408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit parameter"])},
  "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this degree level's status?"])},
  "410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this position's status?"])},
  "411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding a sub-academic unit Can be done when the main-academic unit The status is open or in progress."])},
  "412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the main-academic unit status."])},
  "413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
  "414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
  "416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main-division status will also be changed."])},
  "417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
  "418": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This ", _interpolate(_named("fieldname")), " is currently in use"])},
  "419": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in ", _interpolate(_named("fieldname"))])},
  "420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit"])},
  "421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this academic's unit status?"])},
  "422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example of work period"])},
  "423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example \nEx 1: 1Y 10M 24D \nEx 2: 1Y 5M \nEx 3: 1Y"])},
  "424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum term of tenure is 1 day."])},
  "425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum term of tenure is 60 years."])},
  "426": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edit ", _interpolate(_named("fieldname"))])},
  "427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have entered more characters than what is required by the system."])},
  "428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item was deleted recently, cannot be deleted."])},
  "429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item was deleted recently, cannot be add."])},
  "430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item was status open recently, cannot be deleted."])},
  "431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-academic unit information"])},
  "432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-division information"])},
  "433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" records for "])},
  "434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user information"])},
  "435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descend"])},
  "436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascend"])},
  "437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserID"])},
  "438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
  "439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A - Z"])},
  "440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z -A"])},
  "441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you change the data type. Do you want to change the data type?"])},
  "442": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in the year during ", _interpolate(_named("minyear")), " - ", _interpolate(_named("maxyear")), " B.E."])},
  "443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the amount of money"])},
  "444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text (TH)"])},
  "445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text (EN)"])},
  "446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search groups"])},
  "447": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search ", _interpolate(_named("fieldname"))])},
  "448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User group"])},
  "449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for search results"])},
  "450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Officer"])},
  "452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
  "453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you add information successfully, you won't be able to change user groups."])},
  "454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User information not found"])},
  "455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course opening management"])},
  "456": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course opening"])},
  "457": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course opening time has ended"])},
  "458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H"])},
  "459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic term"])},
  "461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimester"])},
  "462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
  "463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term/academic year"])},
  "464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core courses/major required courses"])},
  "465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major required courses"])},
  "466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major elective courses"])},
  "467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free elective courses"])},
  "468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor courses"])},
  "469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already done"])},
  "470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course title"])},
  "472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course group"])},
  "473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total seats"])},
  "474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available seats"])},
  "475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consecutive course"])},
  "476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students following study-plan"])},
  "477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seats"])},
  "478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for disapproval"])},
  "480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected term of opening"])},
  "481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-study-plan students"])},
  "482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for not on plan"])},
  "483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requester"])},
  "484": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade F"])},
  "485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enrolled"])},
  "486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade W"])},
  "487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade U"])},
  "488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search courses"])},
  "489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening additional course"])},
  "490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student's request"])},
  "491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for request"])},
  "492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request date"])},
  "493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major elective courses"])},
  "494": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses for non-study-plan students"])},
  "495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested courses"])},
  "496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by other programs"])},
  "497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval status"])},
  "498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disapproved"])},
  "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requester"])},
  "501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of study"])},
  "502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time left for opening courses"])},
  "503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not deal in time, please contact the officer."])},
  "504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
  "505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer's request"])},
  "506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
  "507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core courses"])},
  "508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major required courses"])},
  "509": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total seats: ", _interpolate(_named("fieldname")), " seats"])},
  "510": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Available seats: ", _interpolate(_named("fieldname")), " seats"])},
  "511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field sets"])},
  "512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for data"])},
  "513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course title (TH)"])},
  "514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses/major requred"])},
  "515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select heading"])},
  "516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 4"])},
  "517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User group"])},
  "518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User group name"])},
  "519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User group name (TH)"])},
  "520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of users"])},
  "521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user group"])},
  "524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this user group's status?"])},
  "525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter user group information"])},
  "526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide user field access permission"])},
  "527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User field access permission"])},
  "528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group members User group members"])},
  "529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group access control User group access control"])},
  "530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus name"])},
  "531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus abbreviation"])},
  "532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus address"])},
  "533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus name (TH)"])},
  "534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus abbreviation (TH)"])},
  "535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus address (TH)"])},
  "536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division name"])},
  "537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division abbreviation"])},
  "538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division address"])},
  "539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division name (TH)"])},
  "540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division abbreviation (TH)"])},
  "541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division address (TH)"])},
  "542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone (TH)"])},
  "543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax (TH)"])},
  "545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
  "546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position name"])},
  "547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position name (TH)"])},
  "548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit name"])},
  "549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit abbreviation"])},
  "550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit name (TH)"])},
  "551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit abbreviation (TH)"])},
  "552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree level name"])},
  "553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree level name (TH)"])},
  "554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type name"])},
  "555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type name (TH)"])},
  "556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix name"])},
  "557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix name (TH)"])},
  "558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix abbreviation"])},
  "559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix abbreviation (TH)"])},
  "560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name"])},
  "561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name (TH)"])},
  "562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User group"])},
  "563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User group"])},
  "564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Group access control User group access control"])},
  "565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Group access control User group access control"])},
  "566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name"])},
  "567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course closing"])},
  "568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post requisite"])},
  "569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total seats"])},
  "570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seats"])},
  "571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student information"])},
  "572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective students"])},
  "573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add prospective students"])},
  "574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set equivalent course"])},
  "575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set other courses"])},
  "576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set non-prospective students"])},
  "577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-prospective students"])},
  "578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course opening history"])},
  "579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of prospective student"])},
  "580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set exam schedule"])},
  "581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midterm exam"])},
  "582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-exam"])},
  "583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final exam"])},
  "584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
  "585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam information"])},
  "586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set teaching hours"])},
  "587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
  "588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory"])},
  "589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective students"])},
  "590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course opening"])},
  "591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for teaching hours"])},
  "592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-study-plan students"])},
  "593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students submitted request"])},
  "594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional students"])},
  "595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students enrolled in equivalent courses "])},
  "596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students enrolled in other courses"])},
  "597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer"])},
  "599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approver"])},
  "600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add request"])},
  "601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submission date"])},
  "602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of non-prospective students"])},
  "603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combined class"])},
  "604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalent course"])},
  "605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search requests"])},
  "618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for request"])},
  "619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to cancel your request?"])},
  "620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for cancel"])},
  "621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
  "622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully  canceled"])},
  "623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully  added"])},
  "624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
  "625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request submission"])},
  "626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the request information"])},
  "627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define requested course"])},
  "628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search requested course"])},
  "629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For regrade"])},
  "630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For prerequisite course enrollemnt"])},
  "631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For  free elective course enrollment"])},
  "632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another reason"])},
  "633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
  "634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add prospective students"])},
  "636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic"])},
  "637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective student list"])},
  "638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student ID"])},
  "639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term/academic year of course opening"])},
  "640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer"])},
  "641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approver (Date/time)"])},
  "642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of prospective students: 10 "])},
  "643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request history"])},
  "644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example (TH)"])},
  "645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example (EN)"])},
  "646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time  is repeated, please select another one"])},
  "647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time is incorrect"])},
  "648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user group "])},
  "649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
  "650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member setting"])},
  "651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add member"])},
  "652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the center for digital technology"])},
  "654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My favorites"])},
  "657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarks"])},
  "658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
  "659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messeges not found"])},
  "660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert messages"])},
  "662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert messages setting"])},
  "663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search alert messages"])},
  "664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create message"])},
  "665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
  "666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication period"])},
  "667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience"])},
  "668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catagory"])},
  "669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending release"])},
  "670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
  "673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic (EN)"])},
  "674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
  "678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience  detail setting"])},
  "680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From courses"])},
  "681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
  "683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
  "685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic (TH)"])},
  "686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
  "687": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attach files (The maximum numbers is ", _interpolate(_named("numberoffiles")), " files)"])},
  "688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time"])},
  "689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time"])},
  "690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat notifications"])},
  "691": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Repeat notifications (The maximum numbers is  ", _interpolate(_named("numberofrepeats")), " times)"])},
  "692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date/time for repeat notifications"])},
  "693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach only .pdf file"])},
  "694": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This file size is too large. The maximum file size is ", _interpolate(_named("filesize")), " MB."])},
  "695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file already exists. Do you want to replace it?"])},
  "696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic notification message"])},
  "697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
  "698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submodule"])},
  "699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in alert message"])},
  "701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catagory setting"])},
  "702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seach catagory"])},
  "703": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Catagory image (The maximum file size is ", _interpolate(_named("filesize")), "  MB)"])},
  "704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create catagory"])},
  "705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catagory name (EN)"])},
  "706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catagory name (TH)"])},
  "707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages (EN)"])},
  "708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages (TH)"])},
  "709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ext. 12345"])},
  "710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
  "711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy text from the topics"])},
  "712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search subject codes"])},
  "713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for course closing"])},
  "714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User code"])},
  "715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective students"])},
  "716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document tracking"])},
  "718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important message"])},
  "719": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add ", _interpolate(_named("fieldname"))])},
  "720": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edit ", _interpolate(_named("fieldname"))])},
  "721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add member"])},
  "722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search member"])},
  "723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add member"])},
  "724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search users"])},
  "725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to logout?"])},
  "726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for an absence from a class"])},
  "727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for an absence during examination"])},
  "728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for resignation as student"])},
  "729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to register additional credits"])},
  "730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to reduce courses"])},
  "731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for course withdrawal"])},
  "732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for maintaining student status"])},
  "733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for reinstatement"])},
  "734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to register for more than specified credits"])},
  "735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to register for less than specified credits"])},
  "736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advisor"])},
  "737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dean"])},
  "738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you change the publication period, date/time for repeat notification will be lost.\nDo you want to change the publication period?"])},
  "739": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("requestsnumber")), " request(s) for an absence from a class.Please consider and approve. ", _interpolate(_named("Link"))])},
  "740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the request status after 08.30"])},
  "741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the request status next working day"])},
  "742": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for an absence from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ", _interpolate(_named("Date_time")), " Approved"])},
  "743": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for an absence from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ", _interpolate(_named("Date_time")), " wait for approval by lecturer"])},
  "744": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for an absence from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " ", _interpolate(_named("Date_time")), " Lecturer rejected"])},
  "745": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please add more students to the ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " class."])},
  "746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
  "747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type name"])},
  "748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic unit name"])},
  "749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division name"])},
  "750": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " is wait for approval by lecturer"])},
  "751": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been approved"])},
  "752": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by lecturer"])},
  "753": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by advisor"])},
  "754": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by dean"])},
  "755": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " is wait for approval by lecturer"])},
  "756": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been approved"])},
  "757": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " is in progress by CES staff"])},
  "758": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for more/less than specified credits from ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " wait for approval by dean"])},
  "759": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for more/less than specified credits from ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " has been approved"])},
  "760": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The Wi-Fi network ", _interpolate(_named("wifiname")), " does not appear to be connected to the Internet"])},
  "761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to temporarily use cellular data?"])},
  "762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use cellular data "])},
  "763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep trying Wi-Fi"])},
  "764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date/time is incorrect"])},
  "765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We appreciate your feedback"])},
  "766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure, I'll give feedback"])},
  "767": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, thanks"])},
  "768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting with students"])},
  "769": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching"])},
  "770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination"])},
  "771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
  "772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees/scholarships"])},
  "773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My favorites not found"])},
  "774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term"])},
  "775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic year"])},
  "776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this user group?"])},
  "777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cellular network does not appear to be connected to the Internet"])},
  "778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to temporarily use Wi-Fi?"])},
  "779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep trying cellular"])},
  "780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Wi-Fi"])},
  "781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off airplane mode or use Wi-Fi to access data"])},
  "782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to turn off airplane mode?"])},
  "783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Internet connection appears to be offline"])},
  "784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to turn on cellular or Wi-Fi? "])},
  "785": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Use the latest exam data ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear"))])},
  "786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
  "787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organize by lecturer"])},
  "789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organize by CES"])},
  "790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam regulations"])},
  "792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook messenger"])},
  "793": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Today, There are ", _interpolate(_named("totalStudentRequestNumber")), " student(s) in your advisory who have requested to be absent from a class. More details"])},
  "794": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("requestsnumber")), " request(s) for additional credits/reduced courses load/study group changes. Please consider and approve. ", _interpolate(_named("Link"))])},
  "795": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by your advisor"])},
  "796": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to reduce course load for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by your advisor"])},
  "797": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to change study group for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by advisor"])},
  "798": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by advisor"])},
  "799": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to reduce courses load for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by advisor"])},
  "800": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to change study group for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by advisor"])},
  "801": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by lecturer"])},
  "802": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to reduce courses load for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by lecturer"])},
  "803": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to change study group for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by lecturer"])},
  "804": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by lecturer.\nPlease confirm your registration via CES system."])},
  "805": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to reduce courses load for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by lecturer.\nPlease confirm your registration via CES system."])},
  "806": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to change study group for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by lecturer. \nPlease confirm your registration via CES system."])},
  "807": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register additional credits from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by lecturer"])},
  "808": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to reduce courses load from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by lecturer"])},
  "809": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to change study group for a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by lecturer"])},
  "810": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("requestsnumber")), " requests  to register for more/less than specified credits  ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear"))])},
  "811": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for more than specified credits from ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " wait for approval by advisor"])},
  "812": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for less than specified credits from ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " wait for approval by advisor"])},
  "813": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for less than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " wait for approval by dean"])},
  "814": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for more than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " wait for approval by dean"])},
  "815": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for less than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " has been rejected by advisor"])},
  "816": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for more than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " has been rejected by advisor"])},
  "817": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for less than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " has been approved by dean"])},
  "818": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for more than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " has been approved by dean"])},
  "819": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for less than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " has been rejected by dean"])},
  "820": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request to register for more than specified credits ", _interpolate(_named("Term")), "/", _interpolate(_named("academicyear")), " has been rejected by dean"])},
  "821": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("requestsnumber")), " requests  for course withdrawal with grade W "])},
  "822": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal with grade W from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by advisor"])},
  "823": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal with grade W from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " wait for approval by lecturer"])},
  "824": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal with grade W from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " is in progress by CES staff"])},
  "825": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal with grade W from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been rejected by lecturer"])},
  "826": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request for course withdrawal with grade W from a class ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " has been approved"])},
  "827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
  "828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advisees"])},
  "829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval"])},
  "830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User field properties "])},
  "831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set default value in template"])},
  "832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set field type"])},
  "833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set value"])},
  "834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value lists"])},
  "835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai/English"])},
  "837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
  "838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usergroup management"])},
  "840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select From lecturers"])},
  "841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set course coordinator"])},
  "842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course coordinator"])},
  "843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for setting course coordinator"])},
  "844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course coordinator details"])},
  "845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])},
  "846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize"])},
  "847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add course coordinator"])},
  "848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study groups"])},
  "850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set proctors"])},
  "851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for setting proctors"])},
  "852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of proctors"])},
  "853": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("proctors_number")), " proctors/", _interpolate(_named("seats_number")), " seats"])},
  "854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proctors"])},
  "855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*The number of proctors can be decreased depending on student withdrawal.*"])},
  "856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulations for proctors"])},
  "857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set teaching hours"])},
  "858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching hour regulations"])},
  "859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time(s)/Week"])},
  "860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour(s)/Term"])},
  "861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week(s)/Term"])},
  "862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks"])},
  "864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time(s)/Week"])},
  "866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search study groups"])},
  "867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecrure details"])},
  "868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study groups"])},
  "870": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The number of study groups:  ", _interpolate(_named("studygroups_number"))])},
  "871": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "872": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External lecturers"])},
  "873": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this study group?"])},
  "874": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study groups Ex. 1-3,5"])},
  "875": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text display"])},
  "876": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text (TH)"])},
  "877": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text (EN)"])},
  "878": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai/English"])},
  "879": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text (TH)"])},
  "880": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "881": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture week"])},
  "882": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory week"])},
  "883": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign lecturer"])},
  "884": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this prospective student(s) from"])},
  "885": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Something Went wrong, Please try again."])},
  "886": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
  "887": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign prospective students"])},
  "888": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you change the field type. \nDo you want to change the field type?"])},
  "889": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer"])},
  "890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of your advisees who requested absence"])},
  "891": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total: ", _interpolate(_named("totalStudentRequestNumber")), " student(s)"])},
  "892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of absences: "])},
  "893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peroid(s)"])},
  "894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting approval by lecturer"])},
  "895": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer rejected"])},
  "896": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "897": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
  "898": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA"])},
  "899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter data in numberic or decimal number."])},
  "900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
  "901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select template(S)"])},
  "902": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
  "903": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name already exists."])},
  "904": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This ", _interpolate(_named("fieldname")), " has already been used. Please try another name."])},
  "905": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPAX"])},
  "906": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select template"])},
  "907": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai student template default"])},
  "908": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign student template default"])},
  "909": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff template default"])},
  "910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student record"])},
  "911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
  "912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
  "913": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational information"])},
  "914": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family information"])},
  "915": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey"])},
  "916": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduate information"])},
  "917": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID card"])},
  "918": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle name (TH)"])},
  "919": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle name (EN)"])},
  "920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile image"])},
  "921": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
  "922": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood type"])},
  "923": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
  "924": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
  "925": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
  "926": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Race"])},
  "927": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
  "928": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congenital disease"])},
  "929": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "930": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WU email"])},
  "931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal email"])},
  "932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address on ID card"])},
  "933": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line ID"])},
  "934": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House No. or building name"])},
  "935": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street, Lane, Village No."])},
  "936": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "937": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub district"])},
  "938": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current address"])},
  "939": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact address"])},
  "940": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student status"])},
  "941": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student passport score"])},
  "942": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational level"])},
  "943": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
  "944": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry information"])},
  "945": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of entry"])},
  "946": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of entry "])},
  "947": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term of entry"])},
  "948": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of entry"])},
  "949": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
  "950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
  "951": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "952": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add education"])},
  "953": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardian information"])},
  "954": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital Status"])},
  "955": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divorced"])},
  "956": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Married"])},
  "957": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marriage"])},
  "958": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separated"])},
  "959": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alive"])},
  "960": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deceased"])},
  "961": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknow"])},
  "962": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
  "963": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income per month"])},
  "964": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father information"])},
  "965": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother information"])},
  "966": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brothers/sisters information (Including you)"])},
  "967": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of brothers/sisters (Including you)"])},
  "968": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employed borthers/sisters"])},
  "969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of studying brothers/sisters "])},
  "970": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduate status"])},
  "971": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to attend the graduation ceremony?"])},
  "972": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attend"])},
  "973": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not attend"])},
  "974": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workplace (Province)"])},
  "975": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any devices for online classes?"])},
  "976": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "977": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "978": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have the Internet for online classes?"])},
  "979": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you attend the classes on the campus, if you don't have any devices or internet for online classes?"])},
  "980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "981": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "982": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you the eldest child of your family?"])},
  "983": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as guardian information"])},
  "984": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as address on ID card"])},
  "985": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
  "986": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport No."])},
  "987": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of bith"])},
  "988": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
  "989": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address in Thailand"])},
  "991": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent address "])},
  "992": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address information"])},
  "993": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons of reference"])},
  "994": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person of reference in Thailand"])},
  "995": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next of kin"])},
  "996": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship"])},
  "997": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province/City"])},
  "998": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State/County"])},
  "999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as address in Thailand"])},
  "1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact address in Thailand"])},
  "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff ID"])},
  "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position information"])},
  "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position types"])},
  "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional practitioner and general administrator"])},
  "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic administator"])},
  "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic staff"])},
  "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly contractual staff (special cases)"])},
  "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporarily staff"])},
  "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
  "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
  "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System authorization"])},
  "1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
  "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display type"])},
  "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown - search"])},
  "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown - search - checkbox"])},
  "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown"])},
  "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown - checkbox"])},
  "1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkbox"])},
  "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio button"])},
  "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catagory name"])},
  "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catagory name (TH)"])},
  "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a template"])},
  "1025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact address will be lost.\nDo you want to replace it with ID card address?"])},
  "1026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data in the form has not been saved. Do you want to save?"])},
  "1027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully saved"])},
  "1028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose images type only (.jpeg)"])},
  "1029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color photo, head straight, no glasses"])},
  "1030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue background, Walailak University uniform"])},
  "1031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only .jpg file"])},
  "1032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo size 2 inch (4.2×5.5 cm.)"])},
  "1033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examples"])},
  "1034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidelines"])},
  "1035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student admission project"])},
  "1036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
  "1037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Southen region quota"])},
  "1038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission"])},
  "1039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct admission"])},
  "1040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholarship"])},
  "1041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition fee waiver scholarships"])},
  "1042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor degrees"])},
  "1043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master degrees"])},
  "1044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctoral degrees"])},
  "1045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
  "1046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class participants"])},
  "1047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam participants"])},
  "1048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vocational certificate"])},
  "1049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High vocational certificate"])},
  "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High school"])},
  "1051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Formal Education"])},
  "1052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Southern part / The South"])},
  "1053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Central region / Central Region"])},
  "1054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The East"])},
  "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The North East"])},
  "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The North"])},
  "1057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangkok Metropolitan Region"])},
  "1058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The West"])},
  "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not specified"])},
  "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father deceased"])},
  "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother deceased"])},
  "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father and mother deceased"])},
  "1063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father remarried "])},
  "1064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother remarried"])},
  "1065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father and mother married"])},
  "1066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work suspended"])},
  "1067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patron"])},
  "1068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Government officer"])},
  "1069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State enterprise employee"])},
  "1070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private sector employee"])},
  "1071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer/Business owner/Labor"])},
  "1072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farmer/Fishery"])},
  "1073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Government employee"])},
  "1074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unemployed"])},
  "1075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "1076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-income"])},
  "1077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 12,500 baht/month"])},
  "1078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12,500-25,000 baht/month"])},
  "1079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 25,000 baht/month"])},
  "1080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current student"])},
  "1081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retention of student status"])},
  "1082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave of absense"])},
  "1083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave of absense/retention"])},
  "1084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be suspended/retention"])},
  "1085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending University board approval"])},
  "1086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduated"])},
  "1087": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (Resigned)"])},
  "1088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (Non-Enrollment/retention)"])},
  "1089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (Student deceased)"])},
  "1090": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (Nonpayment of tuition fees)"])},
  "1091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (Waiver)"])},
  "1092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (GPAX less than 1.00)"])},
  "1093": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (GPAX less than 1.50)"])},
  "1094": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (GPAX less than 2.00 for 4 consecutive terms)"])},
  "1095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (2 times failed for comprehensive examinations)"])},
  "1096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (Study period exceeded)"])},
  "1097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (F grade for all subjects in the 1st term)"])},
  "1098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination (GPAX less than 2.00 for 6 consecutive terms )"])},
  "1099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiver"])},
  "1100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for student registration (Bachelor Degree)"])},
  "1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
  "1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as contact address in Thailand"])},
  "1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user group"])},
  "1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a user group"])},
  "1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user group"])},
  "1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone number"])},
  "1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office phone number"])},
  "1108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new contact address"])},
  "1109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as guardian address"])},
  "1110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate"])},
  "1111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master student"])},
  "1112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctoral student"])},
  "1113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate (Regular program)"])},
  "1114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate (Regular program: Trimester)"])},
  "1115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate (Regular program: Semester)"])},
  "1116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master student (Regular program)"])},
  "1117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master student (Spacial program)"])},
  "1118": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctoral student (Regular program)"])},
  "1119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctoral student (Spacial program)"])},
  "1120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate (Trimester)"])},
  "1121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate (Semester)"])},
  "1122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set teaching details"])},
  "1123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter teaching details"])},
  "1124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for teaching details"])},
  "1125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total study groups"])},
  "1126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for assigning lecturers"])},
  "1127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already assigned lecturers"])},
  "1128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected groups"])},
  "1129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturers"])},
  "1130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture hours"])},
  "1131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory hours"])},
  "1132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching hours"])},
  "1133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set teaching weeks"])},
  "1134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "1135": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search lecturer"])},
  "1136": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add lecturer"])},
  "1137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter (A-Z) (a-z) (ก-ฮ)"])},
  "1138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set study group details"])},
  "1139": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter study group details"])},
  "1140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set study groups"])},
  "1141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set number of seats per group"])},
  "1142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set classification type"])},
  "1143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program, year of study, school, group of schools"])},
  "1144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
  "1145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam score"])},
  "1146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPAX"])},
  "1147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
  "1148": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Numberseats")), " seats per group"])},
  "1149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group number e.g. 1-5, 6, 7, 10"])},
  "1150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group number"])},
  "1151": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group details"])},
  "1152": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seats to be moved"])},
  "1153": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload exam score file"])},
  "1154": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidelines"])},
  "1155": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Filetypenumber")), ". File type: ", _interpolate(_named("Filetypename"))])},
  "1156": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Filetypenumber")), ". Heading columns consist of:"])},
  "1157": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\n Column ", _interpolate(_named("Columnnumber")), ": ", _interpolate(_named("Columnname"))])},
  "1158": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
  "1159": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template of exam score file"])},
  "1160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "1161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set range of exam score"])},
  "1162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of ranges"])},
  "1163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "1164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More details"])},
  "1165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of seats to be move"])},
  "1166": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is being moved"])},
  "1167": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving completed"])},
  "1168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move students"])},
  "1169": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Move students to group number ", _interpolate(_named("Groupnumber"))])},
  "1170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "1171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "1172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
  "1173": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students"])},
  "1174": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students classified by GPAX"])},
  "1175": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From ", _interpolate(_named("startGPAX")), " to ", _interpolate(_named("endGPAX")), " = ", _interpolate(_named("numberStudents")), " students"])},
  "1176": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\n More than ", _interpolate(_named("startGPAX")), " to ", _interpolate(_named("endGPAX")), " = ", _interpolate(_named("numberStudents")), " students"])},
  "1177": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total = ", _interpolate(_named("totalStudents")), " students"])},
  "1178": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set range of GPAX"])},
  "1179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of GPAX"])},
  "1180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "1181": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "1182": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than"])},
  "1183": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course management history"])},
  "1184": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group(s)"])},
  "1185": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proctor(s)"])},
  "1186": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective students"])},
  "1187": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([": Year ", _interpolate(_named("year"))])},
  "1188": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([": Year ", _interpolate(_named("yearFirst")), " and ", _interpolate(_named("yearSecon"))])},
  "1189": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and up"])},
  "1190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture exam (by lecturer )"])},
  "1191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory exam (by lecturer )"])},
  "1192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture exam (by CES )"])},
  "1193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory exam (by CES )"])},
  "1194": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("numberSeats")), " seats per group"])},
  "1195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign non-pospective students"])},
  "1196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course management history not found"])},
  "1197": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for setting study group details"])},
  "1198": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course closing details"])},
  "1199": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit course closing"])},
  "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search subject code "])},
  "1201": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in more than ", _interpolate(_named("numberCharacters")), " characters"])},
  "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of non-prospective students in other reasons"])},
  "1203": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in not more than ", _interpolate(_named("numberProspectivestudents"))])},
  "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other reasons"])},
  "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory courses"])},
  "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for setting"])},
  "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total non-prospective students"])},
  "1208": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("first")), " of ", _interpolate(_named("total")), " items "])},
  "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete these student in this group. Do you want to delete them?"])},
  "1210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved for student(s) not following study-plan"])},
  "1211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved for requester(s)"])},
  "1212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign prospective students"])},
  "1213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each program"])},
  "1214": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed program"])},
  "1215": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each year of study"])},
  "1216": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed year of study"])},
  "1217": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seach school /program"])},
  "1218": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seach student ID/student name"])},
  "1219": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total students not following study-plan (", _interpolate(_named("number")), " seat(s))"])},
  "1220": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total requester (", _interpolate(_named("number")), " seat(s))"])},
  "1221": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum number of student is ", _interpolate(_named("numberStudent")), " "])},
  "1222": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in at least ", _interpolate(_named("numberStudent")), " student"])},
  "1223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combined class management "])},
  "1224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
  "1225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "1226": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience setting will be lost if you change audience's details to \"all\".\nDo you want to change it?"])},
  "1227": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all students"])},
  "1228": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign total seats"])},
  "1229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
  "1230": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Course opening of ", _interpolate(_named("Subject_codes")), " ", _interpolate(_named("Subjects_name")), " you have set will be lost if you change it to combined class.\nDo you want to change it?"])},
  "1231": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file cannot be uploaded. Please edit the following list.  "])},
  "1232": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No data in colunm ", _interpolate(_named("colunm_name")), " row ", _interpolate(_named("row_number")), "."])},
  "1233": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunm header format is not correct"])},
  "1234": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! \nDue to the large number of users, the system may experience congestion. We're working on a solution and doing our best to restore service as soon as possible."])},
  "1235": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total students"])},
  "1236": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("first")), " of ", _interpolate(_named("total")), " items "])},
  "1237": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign total prospective students"])},
  "1238": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
  "1239": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This study group matched your requested program and year of study"])},
  "1240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available seats"])},
  "1241": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These study groups have enough seats for your request"])},
  "1242": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient seats"])},
  "1243": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These study groups do not have enough seats for your request"])},
  "1244": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new group"])},
  "1245": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective student list"])},
  "1246": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of student to be added"])},
  "1247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some are prospective students.\nIncreasing number of students may affect identified prospective students."])},
  "1248": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of prospective students seats"])},
  "1249": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "1250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search group number"])},
  "1251": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of prospective students"])},
  "1252": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
  "1253": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
  "1254": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seach school/ program/ year of study"])},
  "1255": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students per group"])},
  "1256": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting study group details"])},
  "1257": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective students"])},
  "1258": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set"])},
  "1259": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete these prospective students.\nDo you want to delete them?"])},
  "1260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The system is processing."])},
  "1261": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of study groups can be opened simultaneously at the same time slot."])},
  "1262": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a method for classifying study groups"])},
  "1263": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification method"])},
  "1264": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of groups"])},
  "1265": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination organizer"])},
  "1266": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam period"])},
  "1267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "1268": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data in the form has not been saved. Do you want to save?"])},
  "1269": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter ", _interpolate(_named("number")), " digits student ID"])},
  "1270": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter ", _interpolate(_named("number")), " digits staff ID"])},
  "1271": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add sub-division"])},
  "1272": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add sub-academic unit"])},
  "1273": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to edit, please contact CES system staffs"])},
  "1274": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set message details"])},
  "1275": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set delivery options"])},
  "1276": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set contact details"])},
  "1277": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
  "1278": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
  "1279": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor"])},
  "1280": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have specified students over the maximum number of student.\nDo you want to confirm increasing the student number?"])},
  "1281": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All students in the specified program and year of study "])},
  "1282": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified students"])},
  "1283": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Specified student number ", _interpolate(_named("selectstudent")), "/", _interpolate(_named("maxstudent"))])},
  "1284": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified students over the maximum number."])},
  "1285": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in number of prospective students from ", _interpolate(_named("min")), " to ", _interpolate(_named("max")), "."])},
  "1286": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have entered wrong password more than ", _interpolate(_named("LOGINFAILNUM")), " times. Please contact the officer tel ", _interpolate(_named("LockContact"))])},
  "1287": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have entered wrong password more than ", _interpolate(_named("LOGINFAILNUM")), " times. Please contact the CES system officer, tel ", _interpolate(_named("LockContact"))])},
  "1288": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "1289": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for score verification"])},
  "1290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the score verification request."])},
  "1291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
  "1292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of request"])},
  "1293": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently used requests"])},
  "1294": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other requests"])},
  "1295": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request name"])},
  "1296": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search students"])},
  "1297": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified student number"])},
  "1298": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seach student ID/student name/program/GPAX"])},
  "1299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create requests "])},
  "1301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Type of request"])},
  "1302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit requests"])},
  "1303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search within"])},
  "1304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advisees"])},
  "1305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My courses"])},
  "1306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
  "1307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
  "1308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of audience"])},
  "1309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer"])},
  "1310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES app lecturer users "])},
  "1311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES app student users "])},
  "1312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This student group has been assigned to study groups."])},
  "1313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the number of existing students is less than what you have specified, /n\nthe number of prospective students will be changed to the number of existing students."])},
  "1314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "1315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "1316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPAX less than"])},
  "1317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students in your school/program"])},
  "1318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students in your class"])},
  "1319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
  "1320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In debt"])},
  "1321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporarily terminated"])},
  "1322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminated"])},
  "1323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPAX less than or equal"])},
  "1324": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student name"])},
  "1325": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search audience"])},
  "1326": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience list"])},
  "1327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete these non-prospective students. \nDo you want to delete them?"])},
  "1328": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your Facebook account in the format https://www.facebook/messenger.com/t/ \nor https://www.messenger.com/t/, no space"])},
  "1329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you change the audience. Do you want to change the audience?"])},
  "1330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledged, verifying the score."])},
  "1331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved a draft score verification."])},
  "1332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for Dean’s consideration"])},
  "1333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dean disapproved"])},
  "1334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dean approved"])},
  "1335": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request details"])},
  "1336": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment"])},
  "1337": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "1338": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score verification result"])},
  "1339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score details "])},
  "1340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification result"])},
  "1341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score is correct"])},
  "1342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score is incorrect"])},
  "1343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score correction details"])},
  "1344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to Dean"])},
  "1345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dean’s consideration"])},
  "1346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinion"])},
  "1347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "1348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disapproved"])},
  "1349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consideration result"])},
  "1350": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set enrollment conditions"])},
  "1351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add conditions"])},
  "1352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student received a grade A to D"])},
  "1353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student received a grade S"])},
  "1354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student received a grade A to F"])},
  "1355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrent with"])},
  "1356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student year"])},
  "1357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed the program’s requirements"])},
  "1358": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed the placement test of the program or university’s requirements"])},
  "1359": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student allowed for enrollment by the school board"])},
  "1360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student allowed for enrollment by the program’s lecturers"])},
  "1361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the"])},
  "1362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition list"])},
  "1363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
  "1364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment conditions"])},
  "1365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set conditions"])},
  "1366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions"])},
  "1367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not specified"])},
  "1368": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified student(s)"])},
  "1369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of students"])},
  "1370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "1371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group(s)"])},
  "1372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "1373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add prospective students"])},
  "1374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of prospective students"])},
  "1375": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add students enrolled in equivalent courses"])},
  "1376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add students enrolled in other courses"])},
  "1377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add students not following study-plan"])},
  "1378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pospective students"])},
  "1379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add submitted request students"])},
  "1380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted request students"])},
  "1381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "1382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students enrolled in other courses"])},
  "1383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific course"])},
  "1384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor courses"])},
  "1385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create subject"])},
  "1386": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set subject"])},
  "1387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject name (TH)"])},
  "1388": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject name (EN)"])},
  "1389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject name"])},
  "1390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject description (TH)"])},
  "1391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject description (EN)"])},
  "1392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject description"])},
  "1393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit (Lecture - Lab - Self-study)"])},
  "1394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject type"])},
  "1395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-study hours"])},
  "1396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
  "1397": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom type"])},
  "1398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture room"])},
  "1399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory room"])},
  "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading system"])},
  "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set default credit count in curriculum"])},
  "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count credits"])},
  "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not count credit"])},
  "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for"])},
  "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This curriculum only"])},
  "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This school only"])},
  "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entire university"])},
  "1408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With condition"])},
  "1409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without condition"])},
  "1410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SDG"])},
  "1411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental"])},
  "1412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
  "1413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultural"])},
  "1414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic"])},
  "1415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "1416": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture and laboratory"])},
  "1417": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship or field study"])},
  "1418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "1419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Independent study"])},
  "1420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students added from any program"])},
  "1421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students from any program"])},
  "1422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for permission"])},
  "1423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set student details"])},
  "1424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set number of students"])},
  "1425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already added these students as prospective students:"])},
  "1426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add other students."])},
  "1427": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This ", _interpolate(_named("fieldname")), " has already been used. Please try another name."])},
  "1428": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data in the form has not been saved. Please contact CES system staffs, tel ", _interpolate(_named("LockContact"))])},
  "1429": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data has not been deleted. Please contact CES system staffs, tel ", _interpolate(_named("LockContact"))])},
  "1430": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data has not been edited. Please contact CES system staffs, tel ", _interpolate(_named("LockContact"))])},
  "1431": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Something went wrong. Please contact CES system staffs, \ntel ", _interpolate(_named("LockContact"))])},
  "1432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students not following study-plan"])},
  "1433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student list"])},
  "1434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course coordinator saved a draft verification result."])},
  "1435": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course coordinator acknowleddged and the verification is in process"])},
  "1436": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned the course coordinator to verify the score again."])},
  "1437": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for the student to acknowledge the result."])},
  "1438": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The student acknowledged the result."])},
  "1439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The score has been verified, wait for the student to acknowledge the result."])},
  "1440": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending requests"])},
  "1441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result of the request"])},
  "1442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result of the request"])},
  "1443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "1444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "1445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "1446": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to confirm the approval of the ", _interpolate(_named("totalRequest")), " requests?"])},
  "1447": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for consideration result"])},
  "1448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of request"])},
  "1449": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this request?"])},
  "1450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to cancel your request?"])},
  "1451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you cencel the request. Do you want to cencel the request?"])},
  "1452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This request has been submitted."])},
  "1453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This request has been deleted."])},
  "1454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This request can not be deleted because it is being processed."])},
  "1455": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully added ", _interpolate(_named("Msg")), "."])},
  "1456": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully canceled ", _interpolate(_named("Msg")), "."])},
  "1457": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully edited ", _interpolate(_named("Msg")), "."])},
  "1458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons for denied registration permission"])},
  "1459": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully saved ", _interpolate(_named("Msg")), "."])},
  "1460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumitted the request."])},
  "1461": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposed the request to Dean."])},
  "1462": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved the score verification result."])},
  "1463": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledged the score verification result."])},
  "1464": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The score verification is in process."])},
  "1465": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed the score verification."])},
  "1466": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-exam"])},
  "1467": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
  "1468": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant registration permission"])},
  "1469": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-grant"])},
  "1470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant"])},
  "1471": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All seats are occupied."])},
  "1472": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient lecturers"])},
  "1473": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient equipment"])},
  "1474": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming course opening"])},
  "1475": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of students not following study plan wait for registration permission."])},
  "1476": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students not following study plan wait for registration permission."])},
  "1477": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students not following study plan with permission to register."])},
  "1478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students not following study plan without permission to register for this reason."])},
  "1479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of students not following study plan with permission to register.\n"])},
  "1480": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non-prospective students"])},
  "1481": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of students not following study plan without permission to register\n"])},
  "1482": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete this list of students.\nDo you want to delete it?"])},
  "1483": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of pending request"])},
  "1484": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully sent ", _interpolate(_named("Msg")), "."])},
  "1485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item has been added."])},
  "1486": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of submitted request students wait for registration permission."])},
  "1487": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of submitted request students wait for registration permission."])},
  "1488": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of submitted request students with permission to register."])},
  "1489": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of submitted request students without permission to register for this reason."])},
  "1490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of submitted request students with permission to register.\n"])},
  "1491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of submitted request students without permission to register"])},
  "1492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request of an absence during examination details"])},
  "1493": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request of score verification details"])},
  "1494": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Exam period ", _interpolate(_named("startdate")), "-", _interpolate(_named("enddate")), " "])},
  "1495": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence type"])},
  "1496": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal leave"])},
  "1497": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick leave"])},
  "1498": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course coordinator/lecturer"])},
  "1499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam date"])},
  "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons for absence"])},
  "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact address"])},
  "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Certificate"])},
  "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certified by a government or private hospital."])},
  "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the course coordinator/lecturer"])},
  "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course coordinator(s)"])},
  "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer(s)"])},
  "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted request students with permission to register."])},
  "1508": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted request students without permission to register for this reason."])},
  "1509": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have already added these students as prospective students\n in ", _interpolate(_named("SubjectCode")), " ", _interpolate(_named("SubjectName")), ":"])},
  "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit to"])},
  "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advisory opinion"])},
  "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach only .xls, .xlsx file"])},
  "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for permission"])},
  "1514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permit"])},
  "1515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny"])},
  "1516": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of"])},
  "1517": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total "])},
  "1518": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledged, providing opinion \n(Advisor)"])},
  "1519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved opinion \n(Advisor)"])},
  "1520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledged, providing consideration \n(Dean)"])},
  "1521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledged, providing consideration \n(Lecturer or course coordinator)"])},
  "1522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved consideration results \n(Dean)"])},
  "1523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved consideration results \n(Head of Department acts on behalf of Dean)"])},
  "1524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer or course coordinator opinions"])},
  "1525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam date and time"])},
  "1526": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of students wait for consideration"])},
  "1527": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional instructions"])},
  "1528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make-up exam date and time"])},
  "1529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make-up exam place"])},
  "1530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of considered students."])},
  "1531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please attach only .pdf, .jpeg, .png file"])},
  "1532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students wait for grouping"])},
  "1533": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum ", _interpolate(_named("Numbermaximum")), " students"])},
  "1534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of study groups"])},
  "1535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor, major, program,  year of study,  shool,  school group (default)"])},
  "1536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: students without scores or data, the system sets the score to 0.00"])},
  "1537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the exam score template"])},
  "1538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results of exam score file upload"])},
  "1539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit exam score file."])},
  "1540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total students"])},
  "1541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students with exam scores"])},
  "1542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students without exam scores"])},
  "1543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students with incorrect exam score formats"])},
  "1544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students not found in the exam score file"])},
  "1545": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam scores"])},
  "1546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you cancel. Do you want to cancel?"])},
  "1547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam room type"])},
  "1548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam materials"])},
  "1549": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam with paper/ pencil/ pen"])},
  "1550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam with computer"])},
  "1551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam with tablet"])},
  "1552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam with iPad"])},
  "1553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam with phone"])},
  "1554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam format"])},
  "1555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing exam"])},
  "1556": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listening exam"])},
  "1557": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide-viewing exam"])},
  "1558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaking exam"])},
  "1559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum management"])},
  "1560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search curriculum"])},
  "1561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create curriculum"])},
  "1562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select degree level"])},
  "1563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic data"])},
  "1564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add curriculum name"])},
  "1565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add academic title"])},
  "1566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic title and major"])},
  "1567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name of academic title and major (TH)"])},
  "1568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name of academic title and major (EN)"])},
  "1569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviated name of academic title and major (TH)"])},
  "1570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviated name of academic title and major (EN)"])},
  "1571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add academic title and major"])},
  "1572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
  "1573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major (TH)"])},
  "1574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major (EN)"])},
  "1575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name of academic title"])},
  "1576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name of academic title (TH)"])},
  "1577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name of academic title (EN)"])},
  "1578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviated name of academic title"])},
  "1579": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviated name of academic title (TH)"])},
  "1580": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviated name of academic title (EN)"])},
  "1581": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program name already exists and cannot be saved.  Do you want to edit it?"])},
  "1582": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The academic title already exists and cannot be saved.  Do you want to edit it?"])},
  "1583": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
  "1584": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program title (TH)"])},
  "1585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program title (EN)"])},
  "1586": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam score file"])},
  "1587": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
  "1588": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "1589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students has been grouped"])},
  "1590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset all study groupings"])},
  "1591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign students to transfer group"])},
  "1592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified students over the maximum number."])},
  "1593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait to assign students"])},
  "1594": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully deleted ", _interpolate(_named("studygroup"))])},
  "1595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group to transfer into"])},
  "1596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
  "1597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved consideration results \n(Lecturer or course coordinator)"])},
  "1598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer or course coordinator \nSaved consideration results"])},
  "1599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students \nSumitted the request."])},
  "1600": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Totalcourse")), " course(s)"])},
  "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make-up exam"])},
  "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you change the page. Do you want to change the page?"])},
  "1603": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This will take around ", _interpolate(_named("Start")), "-", _interpolate(_named("End")), " minutes. \n\nPlease do not close this window."])},
  "1604": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([": Year of study ", _interpolate(_named("year"))])},
  "1605": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([": Year of study ", _interpolate(_named("yearFirst")), " and year of study ", _interpolate(_named("yearSecon"))])},
  "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group details"])},
  "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาสอบชดเชย"])},
  "1608": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attach files ", _interpolate(_named("Number"))])},
  "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign individual students"])},
  "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student information"])},
  "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned"])},
  "1612": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Group ", _interpolate(_named("Number"))])},
  "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students to transfer exceeds the available seats in this group. \nDo you confirm transferring and expanding this class group?"])},
  "1614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you reset the class grouping. Do you want to reset?"])},
  "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete these study groups. Do you want to delete them?"])},
  "1616": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully moved ", _interpolate(_named("msg"))])},
  "1617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter students' details to transfer group."])},
  "1618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This curriculum has been deleted, cannot be deleted."])},
  "1619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This curriculum has been deleted, cannot be edited."])},
  "1620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This curriculum was status open recently, cannot be deleted."])},
  "1621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This curriculum was status open recently, cannot be edited."])},
  "1622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete these curriculum. Do you want to delete it?"])},
  "1623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No curriculum data available for filter."])},
  "1624": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total number of credits required for the curriculum: ", _interpolate(_named("Number")), " credits"])},
  "1625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporarily activated"])},
  "1626": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "1627": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporarily suspended"])},
  "1628": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "1629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In process"])},
  "1630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum details"])},
  "1631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum revision"])},
  "1632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set subject codes"])},
  "1633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set curriculum structure"])},
  "1634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation structure"])},
  "1635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set educational plan"])},
  "1636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User academic calendar"])},
  "1637": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Student ID ", _interpolate(_named("ID"))])},
  "1638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to change this program's educational system?"])},
  "1639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your PIN to verify your authorization"])},
  "1640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set teaching hours and classrooms"])},
  "1641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of teaching weeks"])},
  "1642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of teaching hours"])},
  "1643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture room"])},
  "1644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer laboratory"])},
  "1645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Science laboratory"])},
  "1646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "1647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-arrange the classroom"])},
  "1648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching deteils"])},
  "1649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for teaching hours"])},
  "1650": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching hours have been set"])},
  "1651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour(s)/Week"])},
  "1652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week(s)/Term"])},
  "1653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects in specific course"])},
  "1654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thesis"])},
  "1655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperative education"])},
  "1656": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you change subject type. Do you want to change it?"])},
  "1657": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set type of teaching assessment"])},
  "1658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of teaching assessment"])},
  "1659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture and laboratory"])},
  "1660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
  "1661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory"])},
  "1662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field study"])},
  "1663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture and laboratory in Physical Education and Sports Science"])},
  "1664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory in sport subjects"])},
  "1665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic calendar"])},
  "1666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic calendar name"])},
  "1667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational system"])},
  "1668": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar display start date"])},
  "1669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of activities"])},
  "1670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create academic calendar"])},
  "1671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an academic calendar template"])},
  "1672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
  "1673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All template"])},
  "1674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer"])},
  "1675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic calendar details"])},
  "1676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date of academic calendar"])},
  "1677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set user"])},
  "1678": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity details"])},
  "1679": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set activity details"])},
  "1680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity name (TH)"])},
  "1681": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity name (EN)"])},
  "1682": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity description (TH)"])},
  "1683": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity description (EN)"])},
  "1684": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start week"])},
  "1685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End week"])},
  "1686": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start activity date"])},
  "1687": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End activity date"])},
  "1688": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date and time"])},
  "1689": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date and time"])},
  "1690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set notification"])},
  "1691": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Messages ", _interpolate(_named("number"))])},
  "1692": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set audience"])},
  "1693": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement period"])},
  "1694": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before activity start date"])},
  "1695": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
  "1696": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First day of activity"])},
  "1697": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 day before the end date of activity"])},
  "1698": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date of activity"])},
  "1699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement types"])},
  "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
  "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "1703": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "1704": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer/Staff"])},
  "1705": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search subjects"])},
  "1706": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve a lecture room"])},
  "1707": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undergraduate student"])},
  "1708": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form/Request"])},
  "1709": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student handbook"])},
  "1710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduate student"])},
  "1711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules and Regulations"])},
  "1712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program listing"])},
  "1713": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students overall"])},
  "1714": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other links"])},
  "1715": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WU Email"])},
  "1716": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "1717": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check qualifications"])},
  "1718": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search News"])},
  "1719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment News"])},
  "1720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
  "1721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholarship News"])},
  "1722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dormitory News"])},
  "1723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office hours"])},
  "1724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "1725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
  "1726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "1727": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
  "1728": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "1729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal lecturers"])},
  "1730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External lecturers"])},
  "1731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of lecturers"])},
  "1732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching assistant"])},
  "1733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set teaching hours"])},
  "1734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sessions and weeks"])},
  "1735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some sessions and weeks"])},
  "1736": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer list"])},
  "1737": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign study groups to lecturers"])},
  "1738": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecturer assignment status"])},
  "1739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not assigned"])},
  "1740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned some weeks"])},
  "1741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned all weeks"])},
  "1742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total study groups"])},
  "1743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select study group number"])},
  "1744": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total lecturers"])},
  "1745": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned study groups"])},
  "1746": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add groups"])},
  "1747": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel groups"])},
  "1748": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected study group number not found. Please correct the number."])},
  "1749": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course category"])},
  "1750": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All credits"])},
  "1751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free elective course category"])},
  "1752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific course category"])},
  "1753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General education course category"])},
  "1754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create course group"])},
  "1755": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course group name (TH)"])},
  "1756": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course group name (EN)"])},
  "1757": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This course group has subgroups"])},
  "1758": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All course subgroups under this group are mandatory"])},
  "1759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All course subgroups under this group are optional"])},
  "1760": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This course group has no subgroup"])},
  "1761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All courses under this group are mandatory"])},
  "1762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All courses under this group are optional"])},
  "1763": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total number of credits required for the curriculum must not be less than ", _interpolate(_named("minimum")), ", but not exceed ", _interpolate(_named("maximum")), "."])},
  "1764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view courses within the group"])},
  "1765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to assign courses within the group"])},
  "1766": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete this course group. Do you want to delete it"])},
  "1767": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in at least ", _interpolate(_named("numberCredit")), " credits."])},
  "1768": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lecturer"])},
  "1769": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Term ", _interpolate(_named("Number"))])},
  "1770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term"])},
  "1771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic calendar template"])},
  "1772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "1773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity name"])},
  "1774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity description"])},
  "1775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select group"])},
  "1776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select coordinators"])},
  "1777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert messages (TH)"])},
  "1778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert messages (EN)"])},
  "1779": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in not more than ", _interpolate(_named("maxNo"))])},
  "1780": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar has expired, cannot be deleted."])},
  "1781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end week is not specifired"])},
  "1782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign the advisor"])},
  "1783": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total students"])},
  "1784": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for advisor assignment"])},
  "1785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advisor assigned"])},
  "1786": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change advisor"])},
  "1787": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of students"])},
  "1788": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student passport"])},
  "1789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "1790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for confirmation"])},
  "1791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from No."])},
  "1792": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal lecturers"])},
  "1793": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External lecturers"])},
  "1794": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully confirmed advisees"])},
  "1795": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starting from No. ", _interpolate(_named("Number")), " and up"])},
  "1796": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in a start No. lower than the end No."])},
  "1797": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The end No. is not more than ", _interpolate(_named("Number"))])},
  "1798": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in an end No. higher than the start No."])},
  "1799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change advisor"])},
  "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current advisor"])},
  "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New advisor"])},
  "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the advisor"])},
  "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
  "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course title"])},
  "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses in program listing"])},
  "1806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses from other programs"])},
  "1807": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study-plan"])},
  "1808": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recently opened ", _interpolate(_named("Year"))])},
  "1809": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open to other programs"])},
  "1810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are non-study-plan students"])},
  "1811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are submitted request students"])},
  "1812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the head of department"])},
  "1813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set information to import"])},
  "1814": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Semester ", _interpolate(_named("Year"))])},
  "1815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imported results"])},
  "1816": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing examination"])},
  "1817": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected study groups number not found, cannot add them. Please correct the number."])},
  "1818": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing study groups and classification method"])},
  "1819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System is creating study groups"])},
  "1820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing teaching"])},
  "1821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing study groups to lecturers"])},
  "1822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing coordinators"])},
  "1823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully imported"])},
  "1824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])},
  "1825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already assigned lecturers"])},
  "1826": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for seting study groups"])},
  "1827": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import data"])},
  "1828": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing data"])},
  "1829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for program transfer (within Walailak University)"])},
  "1830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for program transfer details (within Walailak University)"])},
  "1831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School and program to transfer into"])},
  "1832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer student qualifications"])},
  "1833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons for program transfer"])},
  "1834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for program transfer fees (within Walailak University)"])},
  "1835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*After payment, the fee is non-refundable."])},
  "1836": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Payment status can be checked on the next working day."])},
  "1837": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status can be checked on the next working day."])},
  "1838": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "1839": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay by"])},
  "1840": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save to device"])},
  "1841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student can not submit the request because your qualifications do not match the requirements. "])},
  "1842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information, contact the head of the department covering your desired program."])},
  "1843": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From semester"])},
  "1844": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to school"])},
  "1845": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to program"])},
  "1846": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head of the department opinions"])},
  "1847": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dean’s consideration/School committee"])},
  "1848": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to"])},
  "1849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance and Accounting staff"])},
  "1850": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for payment"])},
  "1851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "1852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification result"])},
  "1853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your qualifications match the initial requirements. "])},
  "1854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your qualifications do not match the initial requirements. "])},
  "1855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved the transfer"])},
  "1856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disapproved the transfer"])},
  "1857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "1858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "1859": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a department"])},
  "1860": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal program lecturers"])},
  "1861": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External program lecturers"])},
  "1862": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of student passport scores"])},
  "1863": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
  "1864": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail"])},
  "1865": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratitude"])},
  "1866": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discipline"])},
  "1867": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer"])},
  "1868": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership"])},
  "1869": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patriotism"])},
  "1870": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number to be changed"])},
  "1871": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add external lecturers"])},
  "1872": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student status"])},
  "1873": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already assigned course coordinators"])},
  "1874": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note (if any)"])},
  "1875": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note"])},
  "1876": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit note"])},
  "1877": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified students"])},
  "1878": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of teaching weeks per term"])},
  "1879": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of teaching hours per week"])},
  "1880": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week(s)/Term (no teaching)"])},
  "1881": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of teaching hours"])},
  "1882": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add external lecturers"])},
  "1883": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select group"])},
  "1884": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you reset. Do you want to reset?"])},
  "1885": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of teaching hours and lecturer details will be lost if you change teaching hours. Do you want to change?"])},
  "1886": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Week ", _interpolate(_named("Number"))])},
  "1887": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be lost if you delete this education plan.\nDo you want to delete it?"])},
  "1888": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit education plan"])},
  "1889": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete education plan"])},
  "1890": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General education and other subjects."])},
  "1891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects XXX"])},
  "1892": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("termNo")), " terms per academic year"])},
  "1893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entire academic year"])},
  "1894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining available credits"])},
  "1895": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum number exceed ", _interpolate(_named("creditsNumber")), " credits, cannot add any more subjects."])},
  "1896": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot save because  \n  Term 1  \n  - The number must not be less than ", _interpolate(_named("creditsNumber")), " credits.\n  Term 2  \n  - The number must not be less than ", _interpolate(_named("creditsNumber")), " credits."])},
  "1897": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot add ", _interpolate(_named("subjectName")), " in year ", _interpolate(_named("yearNumber")), " term ", _interpolate(_named("termNumber")), " becuese there are prerequisite subjects."])},
  "1898": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot save because the number must not be less than ", _interpolate(_named("creditsNumber")), " credits."])},
  "1899": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aleardy saved year ", _interpolate(_named("yearNumber")), " education plan."])},
  "1900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create  a new account"])},
  "1901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division information"])},
  "1902": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division types"])},
  "1903": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division address"])},
  "1904": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head of department details"])},
  "1905": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position "])},
  "1906": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "1907": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department/ Division Name"])},
  "1908": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as Head of department"])},
  "1909": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Information"])},
  "1910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Email"])},
  "1911": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please confirm your email\n\nThank you for registering with the Educational Qualification Verification System of Walailak University.\n\nPlease confirm your email", _interpolate(_named("Namelink")), "\n\nIf you did not register for the system, please contact us.\n\nThank you,\nThe Center for Educational Services, Walailak University"])}
}